import { ComputedRef, inject, reactive, Ref, unref } from 'vue';

import {
  Comment,
  CommunityAuthor,
  Post
} from '@/__generated__/gateway/graphql';
import { toContentModel } from '@/components/community/utils/converter';
import { ContentModel } from '@/components/community/utils/types';

export const useCommunityAuth = () => {
  const profile =
    inject<ComputedRef<CommunityAuthor | null | undefined>>('profile');
  const initLogin = inject<() => void>('initLogin');

  if (!profile) {
    throw new Error('Community: "profile" not provided.');
  }

  if (!initLogin) {
    throw new Error('Community: "initLogin" not provided.');
  }

  return {
    profile,
    initLogin
  };
};

export const useContentModel = (
  content?: Post | Comment | Ref<Post | Comment>
) => {
  const contentModel = reactive<ContentModel>(
    content
      ? toContentModel(unref(content))
      : {
          __type: 'new',
          body: '',
          images: []
        }
  );

  const reset = () => {
    contentModel.body = '';
    contentModel.images = [];
  };

  return {
    contentModel,
    reset
  };
};
