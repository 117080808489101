import { ReactionType as UiReactionType } from '@hobbii/hobbii-ui';

import {
  Comment,
  Post,
  Reaction as ApiReaction,
  ReactionCounts,
  ReactionType as ApiReactionType
} from '@/__generated__/gateway/graphql';
import { generateProfileUrl } from '@/components/community/utils/helpers';
import { ContentModel } from '@/components/community/utils/types';

const API_REACTION_KEYS = Object.keys(ApiReactionType);

export const stringToApiReaction = (input?: string): ApiReactionType | null => {
  if (input && API_REACTION_KEYS.includes(input)) {
    return ApiReactionType[input as keyof typeof ApiReactionType];
  }

  const test = input as UiReactionType | ApiReactionType | undefined; // trigger switch case completion
  switch (test) {
    case 'love':
      return ApiReactionType.Love;
    case 'in_love':
      return ApiReactionType.InLove;
    case 'cool':
      return ApiReactionType.Cool;
    case 'haha':
      return ApiReactionType.Haha;
    case 'smile':
      return ApiReactionType.Smile;
    case 'wow':
      return ApiReactionType.Wow;
  }

  return null;
};

export const stringToUiReaction = (input?: string): UiReactionType | null => {
  const test = input as UiReactionType | ApiReactionType | undefined; // trigger switch case completion
  switch (test) {
    case 'love':
    case ApiReactionType.Love:
      return 'love';
    case 'in_love':
    case ApiReactionType.InLove:
      return 'in_love';
    case 'cool':
    case ApiReactionType.Cool:
      return 'cool';
    case 'haha':
    case ApiReactionType.Haha:
      return 'haha';
    case 'smile':
    case ApiReactionType.Smile:
      return 'smile';
    case 'wow':
    case ApiReactionType.Wow:
      return 'wow';
  }

  return null;
};

export const fromUiToApiReaction = (
  input?: UiReactionType | null
): ApiReactionType | null => {
  switch (input) {
    case 'love':
      return ApiReactionType.Love;
    case 'in_love':
      return ApiReactionType.InLove;
    case 'cool':
      return ApiReactionType.Cool;
    case 'haha':
      return ApiReactionType.Haha;
    case 'smile':
      return ApiReactionType.Smile;
    case 'wow':
      return ApiReactionType.Wow;
  }

  return null;
};

export const fromApiToUiReaction = (
  input?: ApiReactionType | null
): UiReactionType | null => {
  switch (input) {
    case ApiReactionType.Cool:
      return 'cool';
    case ApiReactionType.Haha:
      return 'haha';
    case ApiReactionType.InLove:
      return 'in_love';
    case ApiReactionType.Love:
      return 'love';
    case ApiReactionType.Smile:
      return 'smile';
    case ApiReactionType.Wow:
      return 'wow';
  }

  return null;
};

export const toContentModel = (content: Post | Comment): ContentModel => ({
  __type: 'update',
  body: content.body ?? '',
  images: content.images ? [...content.images] : []
});

export type ReactionData = { count: number; types: UiReactionType[] };

export const reactionCountsToReactionData = (
  reactionCounts: ReactionCounts
): ReactionData => {
  let count = 0;
  const types: UiReactionType[] = [];
  for (const [key, value] of Object.entries(reactionCounts)) {
    if (key !== '__typename' && typeof value === 'number') {
      if (value > 0) {
        count += value;
        types.push(key as UiReactionType);
      }
    }
  }

  return { count, types };
};

type GroupedUiReactionList = {
  type: UiReactionType;
  reactions: UiReaction[];
}[];

export const getGroupedReaction = (
  reactionCounts: ReactionCounts,
  reactions: UiReaction[]
): GroupedUiReactionList => {
  const list: GroupedUiReactionList = [];
  for (const [key, value] of Object.entries(reactionCounts)) {
    if (key !== '__typename' && typeof value === 'number') {
      if (value > 0) {
        list.push({
          type: key as UiReactionType,
          reactions: reactions.filter((r) => r.type === key)
        });
      }
    }
  }

  return list;
};

// TODO: is this exposed from hobbii-ui - if not, should it?
export type UiReaction = {
  type: UiReactionType;
  user: {
    name: string;
    avatar: string;
    profileUrl: string;
  };
};

export const reactionMapper = (reaction: ApiReaction): UiReaction | null => {
  const type = stringToUiReaction(reaction.label);
  return type && reaction.author
    ? {
        type,
        user: {
          name: reaction.author.display_name,
          avatar: reaction.author.avatar_url,
          profileUrl: generateProfileUrl(reaction.author)
        }
      }
    : null;
};
